// import { setUser, getUser } from "../utils/localStorage"
import { getCookie, setCookie } from "../utils/cookies"
// import Shopify from "../utils/shopify"

let token = "",
  cookiesAgreed = ""
if (typeof document !== "undefined") {
  token = getCookie("accessToken")
  cookiesAgreed = getCookie("cookiesAgreed")
}

const initialState = { accessToken: token, cookiesAgreed }

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case "CUSTOMER_RECEIVED":
      // We do not keep this info in localStorage.  Loaded fresh on My Account Page.
      return { ...state, customer: action.customer }
    case "COOKIES_AGREED":
      // We do not keep this info in localStorage.  Loaded fresh on My Account Page.
      setCookie("cookiesAgreed", true)
      return { ...state, cookiesAgreed: true }
    case "COOKIES_CLOSED":
      // We do not keep this info in localStorage.  Loaded fresh on My Account Page.
      setCookie("cookiesAgreed", false)
      setCookie("cookiesClosed", true)
      return { ...state, cookiesClosed: true }
    case "LOGIN":
      const { accessToken, expiresAt } = action.user
      setCookie("accessToken", accessToken, expiresAt)
      return {
        accessToken: action.user.accessToken,
      }
    case "LOGOUT":
      setCookie("accessToken", "", false)
      return {
        accessToken: null,
      }
    default:
      return state
  }
}

export default userReducer
