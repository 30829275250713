// Nadamoo has a special cart functionality.  Building up packs of 5 or 9.
// To make it work with the ever brittle Shopify Backend.
// Everything lives in localStorage until the moment it's sent to Shopify.

const defaultCartStart = {
  open: false,
  items: [],
  hideUpsell: false,
}

const defaultPackStart = {
  open: false,
  items: [],
  mode: 5,
}

const defaultUserStart = {
  cookiesAgreed: null,
  cookiesClosed: null,
}

const USER_KEY = "user"
const CART_KEY = "nadamooCart"
const PACK_KEY = "nadamooPack"

export const getCart = () => {
  if (typeof window === "undefined") return defaultCartStart
  let cart = JSON.parse(localStorage.getItem(CART_KEY))
  if (!cart || !cart.items) {
    return setCart(defaultCartStart)
  } else return cart
}

export const setCart = (cart) => {
  if (typeof window === "undefined") return
  localStorage.setItem(CART_KEY, JSON.stringify(cart))
  return getCart()
}

export const getPack = () => {
  if (typeof window === "undefined") return defaultPackStart
  let pack = JSON.parse(localStorage.getItem(PACK_KEY))
  if (!pack || !pack.items) {
    return setPack(defaultPackStart)
  } else return pack
}

export const setPack = (pack) => {
  if (pack && pack.items) {
    localStorage.setItem(PACK_KEY, JSON.stringify(pack))
  }
  return getPack()
}

export const getUser = () => {
  if (typeof window === "undefined") return defaultUserStart
  let user = JSON.parse(localStorage.getItem(USER_KEY))

  if (!user || !user.accessToken) return defaultUserStart
  else return user
}

export const setUser = (user) => {
  if (user) localStorage.setItem(USER_KEY, JSON.stringify(user))
  return getUser()
}
