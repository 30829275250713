import { createStore, combineReducers, applyMiddleware, compose } from "redux"
import cartReducer from "./src/reducers/cart"
import packReducer from "./src/reducers/pack"
import userReducer from "./src/reducers/user"
import thunk from "redux-thunk"

const composeEnhancers =
  typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
    : compose

const rootReducer = combineReducers({
  cart: cartReducer,
  pack: packReducer,
  user: userReducer,
})

const enhancer = composeEnhancers(applyMiddleware(thunk))

export default () => createStore(rootReducer, enhancer)
