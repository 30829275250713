import HOST from "./host"

const postOpts = (body) => {
  return {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      credentials: "include",
    },
    method: "POST",
    body: JSON.stringify(body),
  }
}

export default {
  get: (url) => {
    return fetch("//" + HOST + url, {
      credentials: "include",
    })
  },
  post: (url, body) => {
    return fetch("//" + HOST + url, postOpts(body))
  },
}
