export function setCookie(name, value, expires) {
  let HOST
  switch (process.env.GATSBY_VAR_ENV) {
    case "development":
      HOST = "localhost"
      break
    case "staging":
      HOST = "staging-environment.dev"
      break
    case "production":
      HOST = "nadamoo.com"
      break
    default:
      HOST = "nadamoo.com"
      break
  }
  if (typeof document === "undefined") return
  if (!expires)
    expires = new Date(Date.now() + 86400 * 1000 * 365).toUTCString() // One year
  expires = "expires=" + expires
  document.cookie =
    name + "=" + value + ";" + expires + `;domain=${HOST};path=/`
}

export function getCookie(cname) {
  if (typeof document === "undefined") return
  const name = cname + "="
  const ca = document.cookie.split(";")
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i]
    while (c.charAt(0) === " ") {
      c = c.substring(1)
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length)
    }
  }
  return ""
}
