import { getCart, setCart } from "../utils/localStorage"
import Shopify from "../utils/shopify"
const rfdc = require("rfdc")()

const initialState = getCart()

export const createCartAction = () => {
  return (dispatch, getStore) => {
    const cart = getStore().cart
    dispatch({ type: "SEND_CART" })
    return Shopify.post("/create_checkout", cart)
      .then((res) => {
        if (!res.ok) {
          return Promise.reject(res.statusText)
        }
        return res.json()
      })
      .catch((err) => Promise.reject(err))
  }
}

const cartReducer = (state = initialState, action) => {
  const { product, removeIndex } = action
  let shouldOpen = true
  if (typeof window !== "undefined" && window.location.pathname.match(/cart/)) {
    shouldOpen = false
  }

  let newCartState, newItems
  switch (action.type) {
    case "TOGGLE_CART":
      newCartState = {
        ...state,
        open: !state.open,
      }
      setCart(newCartState)
      return newCartState
    case "CLOSE_CART":
      newCartState = {
        ...state,
        open: false,
      }
      setCart(newCartState)
      return newCartState
    case "OPEN_CART":
      newCartState = {
        ...state,
        open: true,
      }
      setCart(newCartState)
      return newCartState
    case "SEND_CART":
      return state
    case "CREATE_CART":
      return state
    case "ADD_TO_CART":
      newItems = rfdc(state.items)
      newCartState = {
        ...state,
        items: rfdc([product, ...newItems]),
        open: shouldOpen,
      }
      setCart(newCartState)
      return newCartState
    case "REMOVE_FROM_CART":
      newItems = state.items.filter((item, i) => {
        if (i !== removeIndex) return item
        return false
      })
      newCartState = {
        ...state,
        items: newItems,
      }
      setCart(newCartState)
      return newCartState
    case "CLEAR_CART_BACKUP":
      newCartState = {
        ...state,
        items: [],
      }
      setCart(newCartState)
      return state
    case "CLEAR_CART":
      newCartState = {
        ...state,
        items: [],
      }
      setCart(newCartState)
      return newCartState
    case "HIDE_FEATURED_UPSELL":
      newCartState = {
        ...state,
        hideUpsell: true,
      }
      setCart(newCartState)
      return newCartState
    default:
      return state
  }
}

export default cartReducer
