let e
switch (process.env.VAR_ENV) {
  case "development":
    e = "//localhost:3000"
    break
  case "staging":
    e = "//nadamoo-shopify.staging-environment.dev"
    break
  case "production":
    e = "//mm.nadamoo.com"
    break
  default:
    e = "//localhost:3000"
    break
}
export default e
