import { getPack, setPack } from "../utils/localStorage"

const initialState = getPack()

const packReducer = (state = initialState, action) => {
  const { mode, removeIndex, product } = action
  if (action.type === "SET_MODE") {
    let error = ""
    if (state.items.length > 5 && action.mode === 5)
      error = "A 5 Pack needs less pints.  Choose wisely!"
    const newPackState = {
      ...state,
      error,
      mode: error ? 9 : mode,
    }
    setPack(newPackState)
    return newPackState
  }
  if (action.type === "CLOSE_PACK") {
    const newPackState = {
      ...state,
      open: false,
    }
    setPack(newPackState)
    return newPackState
  }
  if (action.type === "OPEN_PACK") {
    const newPackState = {
      ...state,
      open: true,
    }
    setPack(newPackState)
    return newPackState
  }
  if (action.type === "ADD_TO_PACK") {
    if (state.items.length >= 9 || !product.name || !product.image)
      return {
        ...state,
        open: !state.open,
      }
    const oldLength = state.items.length
    const newLength = oldLength + 1
    const changeMode = newLength > 5 ? 9 : state.mode
    const newPackState = {
      ...state,
      open: true,
      mode: changeMode,
      items: [...state.items, product],
    }
    setPack(newPackState)
    return newPackState
  }
  if (action.type === "CLEAR_PACK") {
    const newPackState = {
      ...state,
      open: false,
      items: [],
    }
    setPack(newPackState)
    return newPackState
  }
  if (action.type === "REMOVE_FROM_PACK") {
    const newItems = state.items.filter((item, i) => {
      if (i !== removeIndex) return item
      return false
    })
    const newPackState = {
      ...state,
      open: true,
      items: [...newItems],
    }
    setPack(newPackState)
    return newPackState
  } else {
    return state
  }
}

export default packReducer
